import { Invitation } from './invitationTypes';
import { OnboardingState, UserTargets, UserInvites } from './targetsTypes';
import { Request, RequestStatus, Reason } from './requestTypes';

export enum Role {
  Customer = 'CUSTOMER',
  Concierge = 'CONCIERGE',
  Admin = 'ADMIN',
}

export enum BillingType {
  ANNUAL = 'Annual',
  MONTHLY = 'Monthly',
}

export enum PackageType {
  TRIAL = 'Trial',
  CORE = 'Core',
  PROFESSIONAL = 'Professional',
  EXECUTIVE = 'Executive',
}

export enum PackageStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  CANCELED = 'Canceled',
  PENDING = 'Pending',
  TO_BE_CANCELED = 'ToBeCanceled',
}

export enum TeamType {
  INDIVIDUAL = 'Individual',
  TEAM = 'Team',
}

export type PackageInfo = {
  status: PackageStatus;
  billingType: BillingType;
  packageType: PackageType;
  remainingProfilesNumber: number;
  totalProfilesNumber: number;
  extraContactsNumber: number;
  // TODO Make activationDate required once the payment system is automated
  activationDate?: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;
  expDate?: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;
  teamType: TeamType;
  futurePackage?: { billingType: BillingType; packageType: PackageType };
};

export enum PaymentService {
  STRIPE,
}

export type PaymentInfo = {
  paymentService: PaymentService;
  customerId: string;
  subscriptionId?: string;
};

export type ContextPricing = {
  packageInfo: PackageInfo;
  futurePackageInfo: PackageInfo;
  notifiedOnSubscriptionExpiration: boolean;
};

export type PaymentFailedStatusType = {
  amountRemaining: number;
  packageType: string;
  billingType: string;
  failureMessage: string;
  invoiceId: string;
  capacity: number;
};

// Need to create user document in 'users' collection once a user is being signed up into system
export type User = {
  // auth user id
  id?: string;
  role: Role;
  email: string;
  firstName: string;
  secondName: string;
  phoneNumber: string;
  creationDate: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;
  onboardingState: OnboardingState;
  targets?: UserTargets;
  invites?: UserInvites;

  currentRequestStatus: RequestStatus;
  currentRequestId?: string;
  unresolveReason?: Reason;
  lastMessageDate?: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;
  lastMessageOrFeedbackDate?: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;
  isAssigned: boolean;
  assignee?: {
    firstName: string;
    secondName: string;
    uid: string;
  };

  telegramChatId?: string;

  paymentInfo: PaymentInfo;

  // SUB COLLECTIONS
  // list of invitations
  invitations?: Invitation[];
  isBeta: boolean;
  requests?: Request[];

  isFeedbacksResponded: boolean;
  isFeedbacksSeen: boolean;
  isMessagesResponded: boolean;
  isMessagesSeen: boolean;

  source?: string;
  isTrial?: string;

  teamId: string;
  isTeamManager?: boolean;
  teamData?: UserTeamData;

  isAlertsOff?: boolean;

  paymentFailedStatus?: PaymentFailedStatusType;
};

export type CreateUserArg = {
  email: string;
  phoneNumber: string;
  firstName: string;
  secondName: string;
  password: string;
  role: Role;
  targets?: UserTargets;
  teamId?: string;
};

export type UserName = {
  firstName: string;
  secondName: string;
};

export type UpdateUserArg = {
  userId: string;
  email?: string;
  phoneNumber?: string;
  name?: UserName;
};

export type SetPasswordAndVerifyUserArg = { userId: string; newPassword: string };

export type UserTeamData = {
  managerId: string;
  managerName: string;
  managerSecondName: string;
};

export type AttachPaymentMethodArg = {
  userId: string;
  paymentMethodId: string;
};

export type AddSubscriptionArg = {
  userId: string;
  packageInfo: PackageInfo;
  capacity: number;
};

export type UpdatePackageInfoArg = {
  userId: string;
  packageInfo: PackageInfo;
  capacity: number;
};

export type AttachCardAndSubscribeArg = AddSubscriptionArg & {
  paymentMethodId: string;
};

export type CreateTeamArg = {
  userId: string;
  packageInfo: PackageInfo;
  capacity: number;
};

export type SelectPlanArg = {
  userId: string;
  paymentMethodId: string;
  packageInfo: PackageInfo;
  capacity: number;
};

export type RequestExtraContactArg = {
  userId: string;
  contactsNumber: number;
};

export type CardInfo = {
  cardBrand: string;
  cardLast4: string;
  exp_month: number;
  exp_year: number;
  fullName: string | null;
};

export type GetExtraContactPriceArgs = {
  userId: string;
};

export type AddSeatArg = {
  userId: string;
  numberOfTotalSeats: number;
};

export type ChangePlanArg = {
  userId: string;
  packageInfo: PackageInfo;
  capacity: number;
};
