import React, { createContext, FC } from 'react';
import { User } from 'api/types/authTypes';
import { useStateSafely } from '../hooks/useStateSafely';

export const UserContext = createContext<{
  user: User | null | false;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null | false>>;
}>({
  user: null,
  setCurrentUser: () => null,
});

export const CurrentCustomer = createContext<{
  currentCustomer: User | null;
  setCurrentCustomer: React.Dispatch<React.SetStateAction<User | null>>;
  currentCustomerDetails: User | null;
  setCurrentCustomerDetails: React.Dispatch<React.SetStateAction<User | null>>;
}>({
  currentCustomer: null,
  setCurrentCustomer: () => null,
  currentCustomerDetails: null,
  setCurrentCustomerDetails: () => null,
});

export const CurrentCustomerProvider: FC = ({ children }) => {
  const [currentCustomer, setCurrentCustomer] = useStateSafely<User | null>(null);
  const [currentCustomerDetails, setCurrentCustomerDetails] = useStateSafely<User | null>(null);
  return (
    <CurrentCustomer.Provider
      value={{ currentCustomer, setCurrentCustomer, currentCustomerDetails, setCurrentCustomerDetails }}
    >
      {children}
    </CurrentCustomer.Provider>
  );
};

export const UserProvider: FC = ({ children }) => {
  const [user, setCurrentUser] = useStateSafely<User | null | false>(null);
  return <UserContext.Provider value={{ user, setCurrentUser }}>{children}</UserContext.Provider>;
};
