import { User } from 'api/types/authTypes';
import moment from 'moment';
import { CategorizedEntry, GlobalTargets, SalesRangeType } from 'api/types/targetsTypes';
import { SelectOptionsGroupType, OptionType, SelectGroupSelectedType, ColumnOptionType } from 'hooks/useForm';
import { CustomersFiltersType } from 'pages/layout/types/customersTypes';
import { ColumnsType } from 'pages/layout/customers/customersList/configs';
import { ContactSharedPart } from '../api/types/contactTypes';
import { formatPhone } from './validationHelpers';

type AllIDs = (string | number)[];
type ByID = {
  id: string | number;
  [propName: string]: any;
};
type ByIDs = {
  [propName: string]: ByID;
};
type Data = {
  allIDs: AllIDs;
  byIDs: ByIDs;
};
type ArrayOfObjects = { [propName: string]: any }[];
type ObjWithLabel = {
  label: string;
  // value: string;
  [propName: string]: any;
};
type Primitive = string | boolean | number;

export const normalizedData = (arr: ByID[]): Data => {
  const allIDs: AllIDs = [];
  const byIDs: ByIDs = {};
  arr.forEach((el) => {
    allIDs.push(el.id);
    byIDs[el.id] = el;
  });
  return { allIDs, byIDs };
};

export const getAllIds = (arr: ByID[]): AllIDs => arr.map((x) => x.id);

export const getUIState = ({ allIDs, byIDs }: Data): ByID[] => allIDs.map((id) => byIDs[id]);

export const searchInArrayOfObjects = (arr: ObjWithLabel[], searchBy: string): ObjWithLabel[] => {
  return arr.filter((item) => item.label.toLowerCase().indexOf(searchBy.toLowerCase()) !== -1);
};

export const filterInSingleGroup = (arr: SelectOptionsGroupType, searchBy: string): OptionType[] => {
  return arr.subList.filter((item) => item.label.toLowerCase().indexOf(searchBy.toLowerCase()) !== -1);
};

export const searchInCategoryName = (category: OptionType, searchBy: string): boolean => {
  return category.label.toLowerCase().indexOf(searchBy.toLowerCase()) !== -1;
};

export const searchInArrayOfGroupedObjects = (
  arr: SelectOptionsGroupType[],
  searchBy: string
): SelectOptionsGroupType[] => {
  const result: SelectOptionsGroupType[] = [];
  arr.forEach((group) => {
    const hasCategorySearchKey = searchInCategoryName(group.category, searchBy);
    if (hasCategorySearchKey) {
      result.push(group);
    } else {
      const res: OptionType[] = filterInSingleGroup(group, searchBy);
      if (res.length > 0) {
        result.push({ category: group.category, subList: res });
      }
    }
  });
  return result;
};

export const findInArrayOfObjects = (arr: ArrayOfObjects, field: string, prop: Primitive): object | undefined => {
  return arr.find((x) => x[field] === prop);
};

// those are data mutations for select components may be removed in the future if data comes as expected
export const convertToValueLabel: (value: string) => OptionType = (value) => ({
  value,
  label: value,
});

export const convertColumnsToValueLabel = (value: ColumnsType[], exceptRequired?: boolean): ColumnOptionType[] => {
  const arr: ColumnOptionType[] = [];
  value.forEach((item) => {
    if (exceptRequired || (!exceptRequired && !item.required)) {
      arr.push({ value: item.column, label: item.label || '' });
    }
  });
  return arr;
};
export const convertToOptionType: (options: string[]) => OptionType[] = (options) =>
  options.map((x) => convertToValueLabel(x));

export const convertToSelectGroup: (group: CategorizedEntry) => SelectOptionsGroupType | SelectGroupSelectedType = ({
  category,
  subList,
}) => {
  return subList
    ? {
        category: convertToValueLabel(category),
        subList: convertToOptionType(subList),
      }
    : { category: convertToValueLabel(category) };
};

export const convertToSelectOptionsGroupType: (
  options: CategorizedEntry[]
) => (SelectOptionsGroupType | SelectGroupSelectedType)[] = (options) => options.map((x) => convertToSelectGroup(x));

export const convertOptionTypeToString: (options: OptionType[]) => [string] = (options) =>
  options.map(({ value }) => value) as [string];

export const convertToCategorizedEntry: (options: SelectOptionsGroupType[]) => [CategorizedEntry] = (options) =>
  options.map(({ category, subList }) => {
    return subList
      ? {
          category: category.value,
          subList: convertOptionTypeToString(subList),
        }
      : { category: category.value };
  }) as [CategorizedEntry];

export const convertToRange = ({ value }: OptionType): SalesRangeType => {
  return { start: +value.split('-')[0], end: +value.split('-')[1] };
};

export const formatNumber = (x: string, separator: '.' | ',' = ','): string =>
  x.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const convertFromRange = ({ start, end }: SalesRangeType): OptionType => {
  return { value: `${start}-${end}`, label: `$${formatNumber(start.toString())} - $${formatNumber(end.toString())}` };
};

export const convertFromRangeArr = (data: [SalesRangeType]): OptionType[] => {
  return data.map((x) => convertFromRange(x));
};

export const filterCustomers = (filterData: CustomersFiltersType, user: User, uid: string): boolean => {
  const { assignee, status, search, type, managerType } = filterData;
  let assignedToMeFilter = true;
  let unassignedFilter = true;
  if (assignee.indexOf('Assigned to me') > -1) {
    assignedToMeFilter = user.assignee?.uid === uid;
  } else {
    assignedToMeFilter = false;
  }
  if (assignee.indexOf('Unassigned') > -1) {
    unassignedFilter = !user.assignee;
  } else {
    unassignedFilter = false;
  }
  const assigneeFilter = assignee.length === 0 ? true : assignedToMeFilter || unassignedFilter;
  let statusMatch = true;
  if (status.length > 0) {
    statusMatch = status.indexOf(user.currentRequestStatus) > -1;
  }
  let userTypeMatch = true;
  if (type.length > 0) {
    userTypeMatch = user.isBeta ? type.includes('Beta customers') : type.includes('Regular customers');
  }
  let managerTypeMatch = true;
  if (managerType.length > 0 && managerType.includes('All team managers')) {
    managerTypeMatch = !!user.isTeamManager;
  }
  let searchMatch = true;
  if (search !== '') {
    const hasMatchInName = `${user.firstName} ${user.secondName}`.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    const hasMatchInCompany = `${user.targets?.userCompany}`.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    searchMatch = hasMatchInName || hasMatchInCompany;
  }
  return assigneeFilter && statusMatch && searchMatch && userTypeMatch && managerTypeMatch;
};

export const matchSearchData = (data: string, searchData: string): boolean => {
  return data.toLowerCase().indexOf(searchData.toLowerCase()) !== -1;
};

export const contactsSearch = (searchData: string, contact: ContactSharedPart): boolean => {
  const { firstName, secondName, position, company, personalPhone, businessEmail, personalEmail } = contact;
  let searchMatch = true;
  if (searchData) {
    const nameStr = `${firstName} ${secondName}`;
    const companyStr = `${position} ${company}`;
    let phoneStr = '';
    if (personalPhone) {
      phoneStr = `${formatPhone(personalPhone.substring(2))} ${personalPhone}`;
    }
    searchMatch = matchSearchData(
      `${nameStr} ${companyStr} ${phoneStr} ${businessEmail || personalEmail || ''}`,
      searchData.trim()
    );
  }
  return searchMatch;
};

export const searchData = (search: string, matchPropValues: string[]): boolean => {
  let searchMatch = true;
  if (search) {
    searchMatch = matchPropValues.some((value) => matchSearchData(value as string, search.trim()));
  }
  return searchMatch;
};

export const stringFromCategorizedList = (list: CategorizedEntry[] | undefined) => {
  if (!list) return '';
  let string = '';
  list.forEach(({ category, subList }, i) => {
    if (!subList) {
      string += category;
    } else {
      string += `${category}: `;
      if (subList?.length) {
        string += subList.join(', ');
      }
    }
    string += i !== list!.length - 1 ? ', ' : '';
  });
  return string;
};

export const generateAddress = (
  street: string,
  city: string,
  state: string,
  zipCode: string,
  country: string
): string => {
  if (!street && !city && !state && !zipCode && !country) return '';

  let address = '';
  if (street) address += `${street}, `;
  if (city) address += `${city}, `;
  if (state) address += `${state}, `;
  if (zipCode && zipCode !== '0') address += `${zipCode}, `;
  if (country) address += `${country}, `;
  return address.slice(0, -2);
};

export const constructIndustries = (
  targets: GlobalTargets | null,
  selectedItems: string[]
): SelectGroupSelectedType[] => {
  const industries: CategorizedEntry[] = [];
  selectedItems.forEach((selectedItem) => {
    if (targets) {
      targets.servingIndustry.forEach((industry) => {
        let newItem = industries.find((i) => i.category === industry.category);
        if (selectedItem === industry.category) {
          if (!newItem) {
            newItem = {
              category: selectedItem,
            };
            industries.push(newItem);
          }
        } else if (industry.subList) {
          industry.subList.forEach((subCategory) => {
            const current = industries.find((i) => i.category === industry.category);
            const newSubCategoryItem = current?.subList?.find((item) => item.includes(selectedItem));
            if (subCategory.includes(selectedItem) && !newSubCategoryItem) {
              if (!newItem) {
                newItem = {
                  category: industry.category,
                  subList: [subCategory],
                };
                industries.push(newItem);
              } else if (newItem.subList) {
                newItem.subList.push(subCategory);
              } else {
                newItem.subList = [subCategory];
              }
            }
          });
        }
      });
    }
  });

  return convertToSelectOptionsGroupType(industries as [CategorizedEntry]);
};

export const getNotificationTime = (notificationTime: Date) => {
  const now = new Date().getTime();
  const notificationDate = notificationTime.getTime();
  const difference = now - notificationDate;

  const minutes = Math.floor(difference / (60 * 1000));
  const hours = Math.floor(difference / (60 * 60 * 1000));
  const days = Math.floor(difference / (24 * 60 * 60 * 1000));

  const dateNotify = moment(notificationTime).format('MM/DD/yyyy');

  if (days === 1) {
    return 'yesterday';
  }
  if (days > 1) {
    return dateNotify;
  }
  if (hours) {
    return `${hours} hours ago`;
  }
  if (minutes) {
    return `${minutes} minutes ago`;
  }
  return '1 min ago';
};

export const generateListenerId = (userId: string, context: string) => {
  return `${userId}-${context}`;
};
export const industryToString = (servingIndustry?: CategorizedEntry[]) => {
  if (!servingIndustry || servingIndustry.length === 0) {
    return '';
  }
  let result = servingIndustry[0].category;
  for (let i = 1; i < servingIndustry.length; i += 1) {
    result += `, ${servingIndustry[i].category}`;
  }
  return result;
};
