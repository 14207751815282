import { useCallback, useContext, useEffect, useState } from 'react';
import { useDidUpdate } from 'hooks';
import { UserContext } from 'context/authContext';
import app from 'api/firebaseApi';
import { CustomersFiltersType } from 'pages/layout/types/customersTypes';
import { SnackbarContext } from 'context/snackbarContext';
import { PaginationType } from 'components/Pagination';
import { filterCustomers } from 'utils/dataHelpers';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import { ApiHook, ApiHookType, ApiHookWithRefetchType, LoginValuesType, RegisterValuesType } from './types/apiTypes';
import { BillingType, CreateUserArg, PackageType, Role, TeamType, UpdateUserArg, User } from './types/authTypes';
import { CustomerAssignArg, Reason, RequestResolveArg, RequestUnResolveArg } from './types/requestTypes';
import { Colloctions } from './types/firestoreCollections';
import { useStateSafely, useStateSafelyNoParams } from '../hooks/useStateSafely';
import { UserCreateNotification } from './types/notifications';

const db = app.firestore();

export const logout = (): void => {
  app.auth().signOut();
};

export const useAuthState = (): void => {
  const { setCurrentUser } = useContext(UserContext);

  useEffect(() => {
    setCurrentUser(null);
    const listener = app.auth().onAuthStateChanged(
      async (user) => {
        if (user) {
          const userForContext = await db.collection(Colloctions.Users).doc(user.uid).get();
          // after register this event triggers with empty user data
          if (userForContext.exists)
            setCurrentUser({ ...(userForContext.data() as User), id: app.auth().currentUser?.uid });
        } else {
          setCurrentUser(false);
        }
      },
      () => {
        logout();
        setCurrentUser(false);
      }
    );

    return (): void => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.auth()]);
};

export const useLogin = (initialValues: LoginValuesType): ApiHookWithRefetchType<LoginValuesType> => {
  const [loading, setLoading] = useStateSafely(false);
  const [error, setError] = useStateSafely<string>('');
  const [dependancy, setDependancy] = useStateSafely<LoginValuesType>(initialValues); // pass this by argument
  const refetch = useCallback(
    (dep) => {
      setDependancy({ ...dep });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const { email, newPassword } = dependancy;
        await app.auth().signInWithEmailAndPassword(email, newPassword);
        if (!app.auth().currentUser?.emailVerified) {
          app.auth().signOut();
          setError('Please verify your email address!');
        }
      } catch (e) {
        if (e.code === 'auth/wrong-password') {
          setError('The password is invalid.');
        } else {
          setError(e.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [dependancy]);
  return { loading, error, refetch };
};

export const useForget = (): ApiHookWithRefetchType<string> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<string | null>(null);
  const [dependancy, setDependancy] = useState<string>('');

  const refetch = useCallback((dep) => {
    setDependancy(dep);
  }, []);

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        await app.auth().sendPasswordResetEmail(dependancy);
        setData('Please check your email.');
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    })();
  }, [dependancy]);

  return { data, loading, error, refetch };
};

export const useActionCode = (mode: string, actionCode: string): ApiHookType<string> => {
  const [data, setData] = useStateSafely<string | null>(null);
  const [error, setError] = useStateSafely<string | null>(null);
  const [loading, setLoading] = useStateSafely<boolean>(true);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (mode === 'verifyEmail') {
          await app.auth().applyActionCode(actionCode);
        } else {
          const email = await app.auth().verifyPasswordResetCode(actionCode);
          setData(email);
        }
      } catch (e) {
        setError(e.message);
        setData('Please try to reset the password again.');
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, loading };
};

export const useReset = (actionCode: string): ApiHookWithRefetchType<string> => {
  const [loading, setLoading] = useStateSafely(false);
  const [error, setError] = useStateSafely<string>('');
  const [data, setData] = useStateSafely<string | null>(null);
  const [dependancy, setDependancy] = useStateSafely<string>('');

  const refetch = useCallback(
    (dep) => {
      setDependancy(dep);
    },
    [setDependancy]
  );

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        await app.auth().confirmPasswordReset(actionCode, dependancy);
        setData('Your password has been changed successfully!');
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    })();
  }, [dependancy]);
  return { data, loading, error, refetch };
};

export const useRegister = (initialValues: RegisterValuesType): ApiHookWithRefetchType<RegisterValuesType> => {
  const [loading, setLoading] = useStateSafely(false);
  const [error, setError] = useStateSafely<string>('');
  const [dependancy, setDependancy] = useStateSafely<RegisterValuesType>(initialValues);
  const { setCurrentUser } = useContext(UserContext);
  const history = useHistory();
  const { email, password, firstName, secondName, phone, teamId, userCompany } = dependancy;

  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const createUserArg: CreateUserArg = {
          teamId: teamId || '',
          email,
          password,
          firstName,
          secondName,
          phoneNumber: `+1${phone.replace(/\D+/g, '')}`,
          role: Role.Customer,
          targets: {
            currentCustomers: [],
            jobTitles: [],
            nearStates: [],
            servingIndustry: [],
            targetCustomers: [],
            userCompany,
            userJobTitles: [],
          },
        };
        const createUserFunc = app.functions().httpsCallable('createUser');
        await createUserFunc(createUserArg);
        const userCred: firebase.auth.UserCredential = await app.auth().signInWithEmailAndPassword(email, password);

        // eslint-disable-next-line
        const currentUser = await app.auth().currentUser;
        if (!currentUser?.emailVerified && !teamId) {
          await app.auth().currentUser?.sendEmailVerification();
          app.auth().signOut();
          history.push({
            state: { email },
            pathname: '/email-verification',
          });
        }
        // TODO Redirect to page which says the email has been sent
        const userId = userCred.user?.uid;
        const userForContext = await db.collection(Colloctions.Users).doc(userId).get();
        setCurrentUser({ ...(userForContext.data() as User), id: userForContext.id });
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [dependancy]);

  return { loading, error, refetch };
};

export const useGetUpdatedUser = (cb?: () => void, id?: string): ApiHookType<User> => {
  const [dependancy, setDependancy] = useStateSafely<Date | null>(null);
  const [loading, setLoading] = useStateSafely<boolean>(true);
  const [data, setData] = useStateSafely<User | null>(null);
  const { setCurrentUser } = useContext(UserContext);
  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidUpdate(() => {
    (async (): Promise<void> => {
      try {
        const userId = id || app.auth().currentUser?.uid;
        const updatedUser = await db.collection(Colloctions.Users).doc(userId).get();
        setCurrentUser({ ...(updatedUser.data() as User), id: updatedUser.id });
        setData({ ...(updatedUser.data() as User), id: updatedUser.id });
        setLoading(false);
        if (cb) cb();
      } catch (err) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependancy]);
  return { refetch, data, loading };
};

export const useUpdateUser = (cb?: () => void, id?: string): ApiHookWithRefetchType<User> => {
  const [error, setError] = useStateSafely<string>('');
  const [loading, setLoading] = useStateSafely<boolean>(false);
  const [success, setSuccess] = useStateSafely<boolean>(false);
  const [dependancy, setDependancy] = useStateSafely<User | null>(null);
  const { setCurrentUser } = useContext(UserContext);
  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const userId = id || app.auth().currentUser?.uid;
        await db
          .collection(Colloctions.Users)
          .doc(userId)
          .update(dependancy as User);
        const updatedUser = await db.collection(Colloctions.Users).doc(userId).get();
        if (!id) {
          setCurrentUser({ ...(updatedUser.data() as User), id: updatedUser.id });
        }
        setLoading(false);
        setSuccess(true);
        if (cb) cb();
      } catch (err) {
        setLoading(false);
        setError(err.message);
        setSuccess(false);
      }
    })();
  }, [dependancy]);
  return { error, loading, success, refetch };
};
// this code is here in order not to have dependancy circle
export const paginate = <T>(pagination: PaginationType, users: T[]): T[] => {
  const { pageNumber, pageSize } = pagination;
  const size = pageSize || users.length;
  const start = pageNumber === 1 ? 0 : (pageNumber - 1) * size;
  return users.slice(start, start + size);
};

export const useUsers = (
  initialState: CustomersFiltersType,
  cb?: () => void
): ApiHook<User[], CustomersFiltersType> => {
  const [data, setData] = useStateSafely<User[]>([]);
  const [filteredData, setFilteredData] = useStateSafely<User[]>([]);
  const [error, setError] = useStateSafely<string>('');
  const [loading, setLoading] = useStateSafely<boolean>(true);
  const [dependancy, setDependancy] = useStateSafely<CustomersFiltersType>(initialState);
  const [rowsCount, setRowsCount] = useStateSafely<number | null>(null);
  const [filteredRowsCount, setFilteredRowsCount] = useStateSafely<number>(0);
  const { user } = useContext(UserContext);
  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let unsubscribe: { (): void; (): void } | undefined;
    if ((user as User).role !== Role.Customer) {
      (async (): Promise<void> => {
        try {
          unsubscribe = await db
            .collection(Colloctions.Users)
            .where('role', '==', Role.Customer)
            .orderBy('currentRequestStatus')
            .orderBy('lastMessageDate', 'desc')
            .onSnapshot((querySnapshot) => {
              const newData: User[] = [];
              querySnapshot.forEach((docSnapshot) => {
                newData.push({ ...(docSnapshot.data() as User), id: docSnapshot.id });
              });
              const { currentUser } = app.auth();
              const newFilteredData: User[] = newData.filter((x) =>
                filterCustomers(dependancy as CustomersFiltersType, x, currentUser?.uid as string)
              );
              setRowsCount(newData.length);
              setFilteredRowsCount(newFilteredData.length);
              const finalData = paginate(dependancy.pagination, newFilteredData);
              setFilteredData(finalData);
              setData(newData);
              setLoading(false);
            });
          if (cb) cb();
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      })();
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependancy]);
  return { data, filteredData, error, loading, rowsCount, filteredRowsCount, refetch };
};

type DepType = { type: 'resolve' | 'assigne' | 'unresolve'; to: string; data?: { id: string; name: string } };

export const useUpdateCustomer = (
  cb?: () => void
): ApiHookWithRefetchType<DepType> & { resolveSucces: boolean; assigneSuccess: boolean } => {
  const [error, setError] = useStateSafely<string>('');
  const [loading, setLoading] = useStateSafely<boolean>(false);
  const [resolveSucces, setResolveSuccess] = useStateSafely<boolean>(false);
  const [assigneSuccess, setAssigneSuccess] = useStateSafely<boolean>(false);
  const [dependancy, setDependancy] = useStateSafelyNoParams<DepType>();
  const { user } = useContext(UserContext);
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const userId = (dependancy as DepType)?.to;
        const customerDocRef = db.collection(Colloctions.Users).doc(userId);
        const currentUserId: string = (user as User).id as string;
        // TODO(consider how to use the type)
        if ((dependancy as DepType).type === 'resolve') {
          const resolveRequestArg: RequestResolveArg = {
            customerId: customerDocRef.id,
            conciergeId: currentUserId,
          };
          const resolveRequest = app.functions().httpsCallable('resolveCustomerRequest');
          await resolveRequest(resolveRequestArg);
          dispatchSnackbar({ payload: { text: 'Successfully Resolved' } });
          setResolveSuccess(true);
          setAssigneSuccess(false);
        } else if ((dependancy as DepType).type === 'unresolve') {
          const unResolveRequestArg: RequestUnResolveArg = {
            customerId: customerDocRef.id,
            conciergeId: currentUserId,
            reason: dependancy?.data as Reason,
          };
          const unResolveRequest = app.functions().httpsCallable('unResolveCustomerRequest');
          await unResolveRequest(unResolveRequestArg);
          dispatchSnackbar({ payload: { text: 'Successfully Unresolved' } });
          setResolveSuccess(true);
          setAssigneSuccess(false);
        } else if ((dependancy as DepType)?.type === 'assigne') {
          const customerAssignArg: CustomerAssignArg = {
            customerId: customerDocRef.id,
            conciergeId: currentUserId,
          };
          const assignCustomer = app.functions().httpsCallable('assignCustomer');
          await assignCustomer(customerAssignArg);
          dispatchSnackbar({ payload: { text: 'Successfully Assigned' } });
          setResolveSuccess(false);
          setAssigneSuccess(true);
        }
        if (cb) cb();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [dependancy]);
  return { error, loading, resolveSucces, assigneSuccess, refetch };
};

export const useGetCustomer = (cb?: () => void, id?: string): ApiHookWithRefetchType<User> => {
  const [data, setData] = useStateSafely<User | null>(null);
  const [error, setError] = useStateSafely<string>('');
  const [loading, setLoading] = useStateSafely<boolean>(true);
  const [dependancy, setDependancy] = useStateSafelyNoParams<Date>(new Date());

  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const user = await db.collection(Colloctions.Users).doc(id).get();
        setData(user.data() as User);
        if (cb) cb();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependancy]);
  return { data, error, loading, refetch };
};

export const useDeleteCustomer = (userId: string, cb?: () => void): ApiHookWithRefetchType<User | null> => {
  const [error, setError] = useStateSafely<string>('');
  const [loading, setLoading] = useStateSafely<boolean>(false);
  const [dependancy, setDependancy] = useStateSafelyNoParams<string>();
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const refetch = useCallback(
    (dep) => {
      setDependancy(dep);
    },
    [setDependancy]
  );
  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        await db.collection(Colloctions.Users).doc(userId).delete();
        if (cb) cb();
      } catch (err) {
        dispatchSnackbar({
          payload: {
            text: err.message,
            type: 'danger',
            delay: 2000,
          },
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependancy]);
  return { error, loading, refetch };
};
type UpdatePassword = { currentPassword: string; password: string };
export const useUpdatePassword = (cb?: () => void): ApiHookWithRefetchType<User> => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dependancy, setDependancy] = useState<UpdatePassword>();
  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const user = app.auth().currentUser;
        const { currentPassword, password } = dependancy as UpdatePassword;
        const credentials = firebase.auth.EmailAuthProvider.credential(user?.email as string, currentPassword);
        await user?.reauthenticateWithCredential(credentials);
        await user?.updatePassword(password);
        if (cb) cb();
        setError('');
      } catch (err) {
        if (err.code === 'auth/wrong-password') {
          setError('Your current password is wrong!');
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [dependancy]);
  return { error, loading, refetch };
};

export const useUpdateUserAccount = (cb?: () => void): ApiHookWithRefetchType<UpdateUserArg> => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [dependancy, setDependancy] = useState<UpdateUserArg>();
  const { user, setCurrentUser } = useContext(UserContext);
  const refetch = useCallback((dep) => {
    setDependancy(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdate(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        const userId = app.auth().currentUser?.uid;
        const phoneNumber = `+1${dependancy?.phoneNumber?.replace(/\D+/g, '')}`;
        const updateUserFunc = app.functions().httpsCallable('updateUser');
        await updateUserFunc({ ...dependancy, phoneNumber, userId });
        const newUser = { ...(user as User), id: userId };
        if (dependancy?.phoneNumber) {
          newUser.phoneNumber = phoneNumber;
        }
        if (dependancy?.name?.firstName) {
          newUser.firstName = dependancy.name.firstName;
        }
        if (dependancy?.name?.secondName) {
          newUser.secondName = dependancy.name.secondName;
        }
        setCurrentUser({ ...(newUser as User), id: userId });
        setError('');
        if (cb) cb();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [dependancy]);
  return { error, loading, refetch, setError };
};

export type UserCreateNotificationDepType = {
  packageType?: PackageType;
  billingType?: BillingType;
  date?: Date;
};
export const useSendUserCreationNotification = (cb?: () => void): ApiHookWithRefetchType<User> => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dependency, setDependency] = useStateSafely<UserCreateNotificationDepType | null>(null);
  const { user } = useContext(UserContext);
  const refetch = useCallback((dep) => {
    setDependency(dep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);
      try {
        if (dependency && user) {
          const notification = new UserCreateNotification(
            `${user.firstName} ${user.secondName}`,
            user.email,
            TeamType.INDIVIDUAL,
            dependency.packageType,
            dependency.billingType
          );
          if (!dependency?.packageType) delete notification.subscriptionPlan;
          if (!dependency?.billingType) delete notification.billingType;
          await db.collection(Colloctions.SystemNotifications).add({ ...notification });
        }
        if (cb) cb();
        setError('');
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);
  return { error, loading, refetch };
};
