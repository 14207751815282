import React, { createContext, FC, useReducer } from 'react';

export type SnackbarType = { type?: 'danger' | 'success'; text?: string; delay?: number; id?: number };
type Action = { type?: 'ADD' | 'REMOVE'; payload: SnackbarType };

export const SnackbarContext = createContext<{
  snackbar: SnackbarType[];
  dispatchSnackbar: React.Dispatch<Action>;
}>({
  snackbar: [],
  dispatchSnackbar: () => null,
});
const snackbarReducer = (state: SnackbarType[], { type = 'ADD', payload }: Action): SnackbarType[] => {
  if (type === 'ADD') {
    const newState = [...state];
    if (newState.length === 5) {
      newState.shift();
    }
    newState.push({ ...payload, id: Date.now() });
    return newState;
  }
  if (type === 'REMOVE') {
    const stateClone = [...state];
    stateClone.forEach((x, i, list) => x.id === payload.id && list.splice(i, 1));
    return stateClone;
  }
  return state;
};

export const SnackbarProvider: FC = ({ children }) => {
  const [snackbar, dispatchSnackbar] = useReducer(snackbarReducer, []);
  return <SnackbarContext.Provider value={{ snackbar, dispatchSnackbar }}>{children}</SnackbarContext.Provider>;
};
