import React, { memo, FC, CSSProperties } from 'react';
import { createCSSClass } from 'utils/jsxHelpers';

type TextType = 'p' | 'div' | 'span';
export type TextProps = {
  tag?: TextType;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
};

const Text: FC<TextProps> = memo(({ tag = 'p', children, onClick, className, style }) => {
  const TagName = tag as TextType;
  return (
    <TagName onClick={onClick} className={className} style={style}>
      {children}
    </TagName>
  );
});

type TitleProps = {
  tag: 'h1' | 'h2' | 'h3'; // | 'h4' | 'h5' | 'h6';
  className?: string;
};
const titleClasses = {
  h1: 'text-xxl',
  h2: 'text-xl',
  h3: 'text-l',
  // h4: 'text-m',
  // h5: 'text-s',
  // h6: 'text-xs',
};
const Title: FC<TitleProps> = memo(({ tag, children, className }) => {
  const TagName = tag;
  return <TagName className={createCSSClass([`text-bold ${titleClasses[tag]}`, className])}>{children}</TagName>;
});
export { Text, Title };
