import { useEffect, DependencyList, useRef } from 'react';

type Hook = (callback: () => any, dependency?: DependencyList) => void;

const useDidUpdate: Hook = (callback, conditions) => {
  const didMountRef = useRef(false);
  const isSafe = useRef(true);
  useEffect(
    () => {
      if (didMountRef.current && isSafe) {
        callback();
      } else didMountRef.current = true;
      return () => {
        isSafe.current = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    conditions
  );
};

export default useDidUpdate;
