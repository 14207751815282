import { useLayoutEffect } from 'react';

const useLockBodyScroll = (dep?: boolean): void => {
  useLayoutEffect(() => {
    if (dep) {
      document.body.classList.add('overflow-hidden');
      document.documentElement.classList.add('overflow-hidden');
    }
    return (): void => {
      document.body.classList.remove('overflow-hidden');
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, [dep]);
};

export default useLockBodyScroll;
