import React, {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  KeyboardEventHandler,
  ReactNode,
  FocusEventHandler,
} from 'react';
import { createCSSClass } from 'utils/jsxHelpers';
import Spinner from './Spinner';

const btnStaticClassName = 'btn';
const btnClasses = {
  none: '',
  main: 'btn__main bg-rose text-white hover rounded-8',
  outlined: 'btn__outlined px-2 py-1 border',
  gray: 'bg-darkGray border border-gray text-secondary',
  highlighted: 'bg-highlighted text-white rounded-8',
};

type BtnRef = HTMLButtonElement;
type BtnProps = {
  loaderSize?: number;
  type?: 'button' | 'submit';
  mode?: 'none' | 'main' | 'outlined' | 'gray' | 'highlighted'; // | 'filled';
  onClick?: MouseEventHandler<BtnRef>;
  name?: string;
  onKeyDown?: KeyboardEventHandler<BtnRef>;
  onFocus?: FocusEventHandler<BtnRef>;
  value?: string | number;
  disabled?: boolean;
  className?: string;
  id?: string;
  style?: CSSProperties;
  tabIndex?: number;
  form?: string;
  children: ReactNode;
  loading?: boolean;
  loadingClass?: string;
  theme?: string;
  isIcon?: boolean;
  'data-cy'?: string;
};

const Btn = forwardRef<BtnRef, BtnProps>(
  (
    {
      type = 'button',
      mode = 'none',
      onClick,
      name,
      onKeyDown,
      loaderSize,
      value,
      disabled,
      className,
      id,
      style,
      tabIndex,
      form,
      children,
      onFocus,
      loading,
      theme = 'rose',
      loadingClass,
      isIcon,
      'data-cy': data_cy,
    },
    ref
  ) => {
    const btnClass = createCSSClass([btnStaticClassName, btnClasses[mode], className], {
      loading,
      'text-truncate': !isIcon,
      'border-rose hover-bg-rose text-rose rounded-8': theme === 'rose' && mode === 'outlined',
      'border-highlighted hover-bg-highlighted text-highlighted rounded-8':
        theme === 'highlighted' && mode === 'outlined',
      'border-success hover-bg-success text-success rounded-8': theme === 'success' && mode === 'outlined',
    });
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        type={type}
        onClick={onClick}
        onFocus={onFocus}
        name={name}
        onKeyDown={onKeyDown}
        value={value}
        disabled={disabled}
        className={btnClass}
        id={id}
        style={style}
        tabIndex={tabIndex}
        form={form}
        ref={ref}
        data-cy={data_cy}
      >
        {children}
        {loading && <Spinner className={createCSSClass(['ml-xs', loadingClass])} width={loaderSize} />}
      </button>
    );
  }
);

export default Btn;
