import React, { lazy, Suspense, FC, useContext } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { useAuthState } from 'api/authService';
import { UserContext } from 'context/authContext';
import Snackbar from 'components/Snackbars';
import './App.scss';
import app from 'api/firebaseApi';
import { HelmetProvider } from 'react-helmet-async';

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line
  console.log = () => {};
}

export const Auth = lazy(() => import('pages/auth'));
export const Layout = lazy(() => import('pages/layout'));

const App: FC = () => {
  useAuthState();
  const { user } = useContext(UserContext);

  // refresh state
  if (user === null) return null;
  return (
    <HelmetProvider>
      <Snackbar />
      <Router>
        <Suspense fallback={null}>
          <Switch>
            {app.auth().currentUser?.emailVerified && user ? (
              <Route path='/app' component={Layout} />
            ) : (
              <Route path='/' component={Auth} />
            )}
            <Redirect from='*' to='/app' />
          </Switch>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
};

export default App;
