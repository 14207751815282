/* eslint-disable consistent-return */
const validationPatterns = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid email address',
  },
  phone: { regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, msg: 'Please complete phone number' },
  password: {
    regex: /^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z]).*$/,
    msg: 'Passwords must be at least 8 characters long, include at least one letter, and one number.',
  }, // TODO(turn on on deployment)

  url: {
    // eslint-disable-next-line no-useless-escape
    regex:
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=,\w]+@)[A-Za-z0-9.-]+)((?:\/[~%.\w-_]*)?\??(?:[-=&;%@.\w_]*)#?(?:[\w]*))?)/,
    msg: 'Invalid url address',
  },
};
type PatternType = {
  regex: RegExp;
  msg: string;
};
type CheckReturnType = string | undefined;
export type ValidationSchemaType =
  | 'text'
  | 'number'
  | 'email'
  | 'phone'
  | 'password'
  | 'select'
  | 'inputMulti'
  | 'checkbox'
  | 'url'
  | 'passwordConfirm';
export type MsgType = string | null;
export type SchemaType = {
  type: ValidationSchemaType;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  pattern?: PatternType;
  msg?: string;
  requireMsg?: string;
};
export const formatPhone = (phoneNumber: string): string => {
  const x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (!x) return '';
  return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
};
export const toUSAPhone = (phoneNumber: string | null): string => {
  return phoneNumber ? `+1${phoneNumber.toString().replace(/\D+/g, '')}` : '';
};

const validatePattern = (value: string, pattern?: PatternType | PatternType[]): CheckReturnType => {
  if (pattern) {
    if (!Array.isArray(pattern)) {
      if (!new RegExp(pattern.regex).test(value) && value !== '') return pattern.msg;
    } else {
      const matched = pattern.find((x) => !new RegExp(x.regex).test(value));
      if (matched) return matched.msg;
    }
  }
};

const validate = (
  value: string | boolean,
  { type, required, minLength, maxLength, min, max, pattern, msg, requireMsg }: SchemaType,
  password?: string
): CheckReturnType => {
  if (required) {
    if (type === 'checkbox') {
      if (!value) return msg || `Checkbox is required`;
      return;
    }
    if ((value as string).trim() === '') return requireMsg || 'This field is required*';
  }
  // TODO(replace with switch)
  if (type === 'text') {
    // value !== '' this is for correct validation when field is not required
    if (minLength && (value as string).trim().length < minLength && value !== '')
      return msg || `Should contain at least ${minLength} characters`;
    if (maxLength && (value as string).trim().length > maxLength && value !== '')
      return msg || `Should contain a maximum of ${maxLength} characters`;
    if (pattern) return validatePattern(value as string, pattern);
  } else if (type === 'number') {
    if (min && +(value as string).trim() < min) return msg || `Should be greater than or equal to ${min}`;
    if (max && +(value as string).trim() > max) return msg || `Should be less than or equal to ${max}`;
  } else if (type === 'email') {
    return validatePattern(value as string, validationPatterns.email);
  } else if (type === 'phone') {
    return validatePattern(value as string, validationPatterns.phone);
  } else if (type === 'password') {
    return validatePattern(value as string, validationPatterns.password);
  } else if (type === 'url') {
    return validatePattern(value as string, validationPatterns.url);
  } else if (type === 'passwordConfirm') {
    if (password !== (value as string)) return 'Passwords mismatch!';
  }
  return undefined;
};

export const validateSelect = (value: string | null, { required, msg }: SchemaType): CheckReturnType => {
  if (required && value === null) return msg || 'This field is required*';
  return undefined;
};

export const validateInputMulti = (value: any[], { required, msg }: SchemaType): CheckReturnType => {
  if (required && !value.length) return msg || 'This field is required*';
  return undefined;
};

export default validate;

export const isFileImage = (file: File): boolean => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'binary/octet-stream'];
  return file && acceptedImageTypes.includes(file.type);
};

export const returnValidUrl = (url: string) => {
  const reg = new RegExp('^(http|https)://', 'i');
  if (!reg.test(url)) {
    return `http://${url}`;
  }
  return url;
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const COUNT_ABBRS = [
  '',
  '',
  'Million',
  'Billion',
  'Trillion',
  'Quadrillion',
  'Quintillion',
  'Sextillion',
  'Septillion',
];

export const formatNumber = (count: number, withAbbr = true, decimals = 2) => {
  if (count.toString().length < 6) {
    return numberWithCommas(count);
  }
  const i = count === 0 ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = `${numberWithCommas(parseFloat((count / 1000 ** i).toFixed(decimals)))}`;
  if (withAbbr) {
    result += ` ${COUNT_ABBRS[i]}`;
  }
  return result;
};

export const isMobileDevice = (): boolean => {
  const vendor = navigator.userAgent || navigator.vendor;
  return !!(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      vendor
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      vendor.substr(0, 4)
    )
  );
};
