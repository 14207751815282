import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'context/snackbarContext';
import { CurrentCustomerProvider, UserProvider } from 'context/authContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import { FeedbackProvider } from './context/feedbackContext';

const stripPublishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;

const stripePromise = loadStripe(stripPublishableKey);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise} options={{ locale: 'en' }}>
      <UserProvider>
        <SnackbarProvider>
          <CurrentCustomerProvider>
            <FeedbackProvider>
              <App />
            </FeedbackProvider>
          </CurrentCustomerProvider>
        </SnackbarProvider>
      </UserProvider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);
