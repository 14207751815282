import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import firebaseConfig from 'configs/firebase';

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export const { fromDate } = firebase.firestore.Timestamp;
export const { now } = firebase.firestore.Timestamp;
export const deleteField = firebase.firestore.FieldValue.delete;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const FirebaseFieldPath = firebase.firestore.FieldPath;
export default app;
