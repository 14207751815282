import { useEffect, MutableRefObject } from 'react';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type HandlerType = (event: PossibleEvent) => void;
type RefType = MutableRefObject<HTMLElement | null> | null;
type Hook = (ref: RefType, handler: HandlerType, exceptionRef?: RefType[]) => void;
const events: HandledEvents = [MOUSEDOWN, TOUCHSTART];

const useOnClickOutside: Hook = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const listener = (event: PossibleEvent): void => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref) return;
      const { current } = ref;
      if (
        !current ||
        (exceptionRef && !exceptionRef.length) ||
        current.contains(event.target as Node) ||
        (exceptionRef && exceptionRef.some((item) => item?.current?.contains(event.target as Node)))
      ) {
        return;
      }
      handler(event);
    };
    events.forEach((event) => {
      document.addEventListener(event, listener);
    });
    return (): void => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [ref, handler, exceptionRef]); // ... passing it into this hook. // ... but to optimize you can wrap handler in useCallback before ... // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
};

export default useOnClickOutside;
