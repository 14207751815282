import React, { createContext, FC, useState } from 'react';
import { EntityType } from '../api/types/contactTypes';
import { User } from '../api/types/authTypes';
import { EntityAcceptedStatus } from '../api/types/companyTypes';

type SelectedEntityType = {
  entityId: string;
  entityType: EntityType;
  name: string;
  imageURL: string;
  acceptedStatus?: EntityAcceptedStatus;
} | null;
export const FeedbackContext = createContext<{
  feedbackOpen: boolean;
  setFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEntity: SelectedEntityType;
  setSelectedEntity: React.Dispatch<React.SetStateAction<SelectedEntityType>>;
  currentCustomerFeedback: User | null;
  setCurrentCustomerFeedback: React.Dispatch<React.SetStateAction<User | null>>;
  newFeedbacks: string[];
  setNewFeedbacks: React.Dispatch<React.SetStateAction<string[]>>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  feedbackOpen: false,
  setFeedbackOpen: () => null,
  selectedEntity: null,
  setSelectedEntity: () => null,
  currentCustomerFeedback: null,
  setCurrentCustomerFeedback: () => null,
  newFeedbacks: [],
  setNewFeedbacks: () => [],
  isFocused: false,
  setIsFocused: () => false,
});

export const FeedbackProvider: FC = ({ children }) => {
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntityType>(null);
  const [currentCustomerFeedback, setCurrentCustomerFeedback] = useState<User | null>(null);
  const [newFeedbacks, setNewFeedbacks] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  return (
    <FeedbackContext.Provider
      value={{
        currentCustomerFeedback,
        setCurrentCustomerFeedback,
        feedbackOpen,
        newFeedbacks,
        setNewFeedbacks,
        setFeedbackOpen,
        selectedEntity,
        setSelectedEntity,
        isFocused,
        setIsFocused,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
