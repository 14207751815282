import { BillingType, PackageType, TeamType } from './authTypes';

export enum NotificationType {
  NEW_USER = 'NEW_USER',
  SUBSCRIPTION_EXPIRES = 'SUBSCRIPTION_EXPIRES',
  SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL',
}

export class NotificationModel {
  type: NotificationType;

  constructor(type: NotificationType) {
    this.type = type;
  }

  getType(): NotificationType {
    return this.type;
  }

  static create(data: any): NotificationModel {
    return new NotificationModel(data.type);
  }
}

export class UserCreateNotification extends NotificationModel {
  // eslint-disable-next-line
  displayName: string;

  email: string;

  subscriptionPlan?: PackageType;

  billingType?: BillingType;

  teamType?: TeamType;

  constructor(
    displayName: string,
    email: string,
    teamType?: TeamType,
    subscriptionPlan?: PackageType,
    billingType?: BillingType
  ) {
    super(NotificationType.NEW_USER);
    this.displayName = displayName;
    this.email = email;
    this.subscriptionPlan = subscriptionPlan;
    this.billingType = billingType;
    this.teamType = teamType;
  }

  toString(): string {
    return `${this.type}: ${this.displayName} (${this.email}) has been registered ${
      this.subscriptionPlan ? `with ${this.billingType} / ${this.subscriptionPlan} plan` : '.'
    } ${this.teamType ? ` as ${this.teamType}` : ''}`;
  }

  // TODO Find a better solution to not get any object as argument
  static create(data: any): UserCreateNotification {
    return new UserCreateNotification(
      data.displayName,
      data.email,
      data.teamType,
      data.subscriptionPlan,
      data.billingType
    );
  }
}

export class SubscriptionNearToExpNotification extends NotificationModel {
  // eslint-disable-next-line
  displayName: string;

  email: string;

  subscriptionPlan: PackageType;

  expDate: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp;

  constructor(
    displayName: string,
    email: string,
    subscriptionPlan: PackageType,
    expDate: FirebaseFirestore.FieldValue | FirebaseFirestore.Timestamp
  ) {
    super(NotificationType.SUBSCRIPTION_EXPIRES);
    this.displayName = displayName;
    this.email = email;
    this.subscriptionPlan = subscriptionPlan;
    this.expDate = expDate;
  }

  toString(): string {
    return `${this.type}: The customer's ${this.displayName} (${this.email}) subscription plan ${this.subscriptionPlan} going to expire on ${this.expDate}.`;
  }

  static create(data: any): SubscriptionNearToExpNotification {
    return new SubscriptionNearToExpNotification(data.displayName, data.email, data.subscriptionPlan, data.expDate);
  }
}

export class SubscriptionUpdateNotification extends NotificationModel {
  // eslint-disable-next-line
  displayName: string;

  email: string;

  subscriptionPlan: PackageType;

  billingType: BillingType;

  constructor(displayName: string, email: string, newPlan: PackageType, newBillingType: BillingType) {
    super(NotificationType.SUBSCRIPTION_UPDATE);
    this.displayName = displayName;
    this.email = email;
    this.subscriptionPlan = newPlan;
    this.billingType = newBillingType;
  }

  toString(): string {
    return `${this.type}: The customer's ${this.displayName} (${this.email}) requested new package: ${this.billingType} / ${this.subscriptionPlan}.`;
  }

  static create(data: any): SubscriptionUpdateNotification {
    return new SubscriptionUpdateNotification(data.displayName, data.email, data.subscriptionPlan, data.billingType);
  }
}

export class SubscriptionCancelNotification extends NotificationModel {
  // eslint-disable-next-line
  displayName: string;

  email: string;

  subscriptionPlan: PackageType;

  billingType: BillingType;

  constructor(displayName: string, email: string, currentPlan: PackageType, currentBillingType: BillingType) {
    super(NotificationType.SUBSCRIPTION_CANCEL);
    this.displayName = displayName;
    this.email = email;
    this.subscriptionPlan = currentPlan;
    this.billingType = currentBillingType;
  }

  toString(): string {
    return `${this.type}: The customer's ${this.displayName} (${this.email}) requested to cancel the subscription plan: ${this.billingType} / ${this.subscriptionPlan}.`;
  }

  static create(data: any): SubscriptionCancelNotification {
    return new SubscriptionCancelNotification(data.displayName, data.email, data.subscriptionPlan, data.billingType);
  }
}
