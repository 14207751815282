export enum Colloctions {
  Users = 'users',
  Invitations = 'invitations',
  Messages = 'messages',
  Feedbacks = 'feedbacks',
  FeedbacksContext = 'feedbacksContext',
  FeedbackStatuses = 'feedbackStatuses',
  Requests = 'requests',
  Targets = 'targets',
  PublicAssets = 'publicAssets',
  UnresolveReasons = 'unresolveReasons',
  Contacts = 'contacts',
  Companies = 'companies',
  CustomerContacts = 'customerContacts',
  CustomerCompanies = 'customerCompanies',
  CustomerSFCreds = 'customerSFCreds',
  CustomerThirdPartyCreds = 'customerThirdPartyCreds',
  ExportedEntities = 'exportedEntities',
  Notes = 'notes',
  Notifications = 'notifications',
  Context = 'context',
  SystemNotifications = 'systemNotifications',
  Teams = 'teams',
  Members = 'members',
  TeamInvitations = 'teamInvitations',
  Packages = 'packages',
  StripeInvoiceEvents = 'stripeInvoiceEvents',
}

export enum SubCollection {
  User = 'user',
  UserCompanies = 'userCompanies',
  UserContacts = 'userContacts',
  UserMessages = 'userMessages',
  UserInvitations = 'userInvitations',
  UserContactNotes = 'userContactNotes',
  UserCompanyNotes = 'userCompanyNotes',
  UserRequests = 'userRequests',
  UserExportedEntities = 'userExportedEntities',
  UserNotifications = 'userNotifications',
  UserContext = 'userContext',
  UserFeedbacks = 'userFeedbacks',
  UserFeedbacksContext = 'userFeedbacksContext',
  UserFeedbackStatuses = 'userFeedbackStatuses',
  UserStripeInvoiceEvents = 'userStripeInvoiceEvents',
  CustomerNotes = 'customerNotes',
  TeamContacts = 'teamContacts',
  TeamCompanies = 'teamCompanies',
  TeamInvitations = 'teamInvitations',
  TeamMembers = 'teamMembers',
}

export enum DocumentIds {
  LastSeenNotification = 'lastSeenNotification',
  LastSeenMessage = 'lastSeenMessage',
  NotifiedStates = 'notifiedStates',
  Pricing = 'pricing',
  Feedbacks = 'feedbacks',
}

export class SubColloctions {
  static [SubCollection.User](userId: string) {
    return `${Colloctions.Users}/${userId}`;
  }

  static [SubCollection.UserMessages](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Messages}`;
  }

  static [SubCollection.UserInvitations](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Invitations}`;
  }

  static [SubCollection.UserContacts](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.CustomerContacts}`;
  }

  static [SubCollection.UserContactNotes](userId: string, contactId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.CustomerContacts}/${contactId}/${Colloctions.Notes}`;
  }

  static [SubCollection.UserCompanies](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.CustomerCompanies}`;
  }

  static [SubCollection.UserCompanyNotes](userId: string, companyId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.CustomerCompanies}/${companyId}/${Colloctions.Notes}`;
  }

  static [SubCollection.UserFeedbacks](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Feedbacks}`;
  }

  static [SubCollection.UserFeedbacksContext](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Context}/${DocumentIds.Feedbacks}/${Colloctions.FeedbacksContext}`;
  }

  static [SubCollection.UserFeedbackStatuses](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.FeedbackStatuses}`;
  }

  static [SubCollection.UserNotifications](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Notifications}`;
  }

  static [SubCollection.UserContext](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Context}`;
  }

  static [SubCollection.UserRequests](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Requests}`;
  }

  static [SubCollection.UserExportedEntities](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.ExportedEntities}`;
  }

  static [SubCollection.CustomerNotes](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.Notes}`;
  }

  static [SubCollection.TeamContacts](teamId: string) {
    return `${Colloctions.Teams}/${teamId}/${Colloctions.Contacts}`;
  }

  static [SubCollection.TeamCompanies](teamId: string) {
    return `${Colloctions.Teams}/${teamId}/${Colloctions.Companies}`;
  }

  static [SubCollection.TeamInvitations](teamId: string) {
    return `${Colloctions.Teams}/${teamId}/${Colloctions.TeamInvitations}`;
  }

  static [SubCollection.TeamMembers](teamId: string) {
    return `${Colloctions.Teams}/${teamId}/${Colloctions.Members}`;
  }

  static [SubCollection.UserStripeInvoiceEvents](userId: string) {
    return `${Colloctions.Users}/${userId}/${Colloctions.StripeInvoiceEvents}`;
  }
}
