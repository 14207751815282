import React, { FC } from 'react';
import { createCSSClass } from 'utils/jsxHelpers';

type ProgressbarTypes = {
  loading: boolean;
  className?: string;
};

export const Progressbar: FC<ProgressbarTypes> = ({ loading, className }) => {
  return loading ? <div className={createCSSClass(['progress', className])} /> : null;
};

type SpinnerProps = {
  className?: string;
  width?: number;
};
const Spinner: FC<SpinnerProps> = ({ className, width }) => {
  return (
    <span className={createCSSClass(['spinner', className])} style={width ? { width, height: width } : undefined} />
  );
};

export default Spinner;
