import React, { useEffect, useState, FC, useContext } from 'react';
import { SnackbarType, SnackbarContext } from 'context/snackbarContext';
import { Text } from './Typography';
import { IconSuccess, IconClose, IconDanger } from './Icons';
import { createCSSClass } from '../utils/jsxHelpers';
import Btn from './Buttons';

const snackbarClass = 'snackbar d-flex align-items-center mw-100 py-xxs pr-4 pl-xs mt-1 rounded-4 bg-white elevation-1';
const snackbarIconClass = 'snackbar_icon mr-xs';
const snackbarContentClass = 'snackbar_content';
// const snackbarTitleClass = 'snackbar_title text-bold text-l';
const snackbarTextClass = 'snackbar_text';
const snackbarRemoveClass = 'snackbar_remove p-xs';

type SnackType = SnackbarType & { position: number };

const Snack: FC<SnackType> = ({ type = 'success', text, delay = 3000, id, position }) => {
  const [open, setOpen] = useState(true);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const selfRemove = (): void => setOpen(false);

  const onAnimationEnd = (): void => {
    if (!open) dispatchSnackbar({ type: 'REMOVE', payload: { id } });
  };

  useEffect(() => {
    const timerId = setTimeout(selfRemove, delay);
    return (): void => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Icon = type === 'danger' ? IconDanger : IconSuccess;

  return (
    <div
      style={{ top: position }}
      onAnimationEnd={onAnimationEnd}
      className={createCSSClass([`${snackbarClass} snackbar__${type}`], {
        'slide-right': open,
        'slide-right-back': !open,
      })}
    >
      <div className={snackbarIconClass}>
        <Icon color={type} width={22} height={22} />
      </div>
      <div className={snackbarContentClass}>
        {/* <Text tag='div' className={snackbarTitleClass}>
          {type === 'danger' ? 'error' : 'success'}
        </Text> */}
        <Text tag='div' className={snackbarTextClass}>
          {text}
        </Text>
      </div>
      <Btn className={snackbarRemoveClass} onClick={selfRemove}>
        <IconClose color='secondary' width={15} height={15} />
      </Btn>
    </div>
  );
};

const Snackbar: FC = () => {
  const { snackbar } = useContext(SnackbarContext);
  return snackbar.length ? (
    <>
      {snackbar.map(({ type, text, delay, id }, i) => (
        <Snack key={id} type={type} text={text} delay={delay} position={i * 66} id={id} />
      ))}
    </>
  ) : null;
};

export default Snackbar;
