import { Ref, MutableRefObject } from 'react';

type CssClass = { [propName: string]: string | number | boolean | undefined | null };
export const createCSSClass = (staticClass: (string | undefined)[], dynamicClass?: CssClass): string => {
  let result = staticClass.join(' ').trimEnd();
  if (!dynamicClass) return result;
  Object.entries(dynamicClass).forEach(([key, value]) => {
    if (value) result += ` ${key}`;
  });
  return result.trim();
};

type RefTypes = Ref<HTMLElement> | MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void);
export const mergeRefs =
  (...refs: Array<RefTypes>) =>
  (ref: HTMLElement | null): void => {
    refs.forEach((resolvableRef) => {
      if (typeof resolvableRef === 'function') {
        resolvableRef(ref);
        // eslint-disable-next-line no-param-reassign
      } else if (resolvableRef) (resolvableRef as MutableRefObject<HTMLElement | null>).current = ref;
    });
  };

export const getOffset = (el: HTMLElement, h: number, w = 0): { x?: 'right' | 'left'; y: 'up' | 'down' } => {
  const { top, left, height, width } = el.getBoundingClientRect();
  const y = top + h + height >= window.innerHeight ? 'up' : 'down';
  const x = left + w + width >= window.innerWidth ? 'right' : 'left';
  return { x, y };
};

type ValueType = string;
type LabelType = string;
type OptionType = { value: ValueType; label: LabelType };
export const navigateWithArrow = (
  content: HTMLElement,
  options: OptionType[],
  cursor: number,
  dir: 'up' | 'down'
): void => {
  const current = content.querySelectorAll('.select_option__hover')[0] as HTMLElement;
  const top = current.offsetTop;
  const { scrollTop } = content;
  const cHeight = content.clientHeight;
  if (dir === 'down') {
    if (cursor === options.length - 1) {
      content.scrollTo({ top: 0 });
    } else if (scrollTop + cHeight < top + 80) content.scrollTo({ top: scrollTop + 40 });
  } else if (cursor === 0) {
    // when navigating from top to bottom with arrow key
    content.scrollTo({ top: top + cHeight + 80 });
  } else if (scrollTop > top - 40) content.scrollTo({ top: scrollTop - 40 });
};
