import { useEffect } from 'react';

const useBeforeUnload = (execute: boolean): void => {
  const handleBeforeunload = (evt: BeforeUnloadEvent): string | null => {
    if (!execute) return null;
    evt.preventDefault();
    const returnValue = 'Changes may be lost';
    // eslint-disable-next-line no-param-reassign
    evt.returnValue = returnValue;
    // }
    return returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    return (): void => window.removeEventListener('beforeunload', handleBeforeunload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);
};

export default useBeforeUnload;
